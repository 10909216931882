"use client";

import { FC } from "react";
import {
	NextUIProvider as _NextUiProvider,
	NextUIProviderProps,
} from "@nextui-org/system";
import { useRouter } from "next/navigation";

type Props = NextUIProviderProps;

export const NextUiProvider: FC<Props> = (props) => {
	const router = useRouter();

	return <_NextUiProvider {...props} navigate={router.push} />;
};
