"use client";

import { queryClient } from "@/clients/queryClient";
import {
	QueryClientProvider as _QueryClientProvider,
	QueryClientProviderProps,
} from "@tanstack/react-query";
import { FC } from "react";

type Props = Omit<QueryClientProviderProps, "client">;

export const QueryClientProvider: FC<Props> = (props) => {
	return <_QueryClientProvider client={queryClient} {...props} />;
};
