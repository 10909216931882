"use client";

import {
	createContext,
	useState,
	useEffect,
	PropsWithChildren,
	FC,
} from "react";
import { z } from "zod";

const CurrencyCode = z.enum(["NZD", "EUR", "USD"]);
export type CurrencyCode = z.infer<typeof CurrencyCode>;

export type CurrencyContextType = {
	currencyCode: CurrencyCode;
	setCurrencyCode: (currencyCode: CurrencyCode) => void;
};

export const CurrencyContext = createContext<CurrencyContextType | undefined>(
	undefined,
);

type CurrencyProviderProps = PropsWithChildren<{}>;

const currencyCodeLocalStorageKey = "selectedCurrencyCode";

export const CurrencyProvider: FC<CurrencyProviderProps> = ({ children }) => {
	const [currencyCode, setCurrencyCode] = useState<CurrencyCode>("NZD");
	const [localStorageRetrieved, setLocalStorageRetrieved] = useState(false);

	useEffect(() => {
		const storedCurrencyCode = localStorage.getItem(
			currencyCodeLocalStorageKey,
		);

		if (storedCurrencyCode) {
			const parsedStoredCurrencyCode =
				CurrencyCode.safeParse(storedCurrencyCode);

			if (parsedStoredCurrencyCode.success) {
				setCurrencyCode(parsedStoredCurrencyCode.data);
			} else {
				localStorage.removeItem(currencyCodeLocalStorageKey);
			}
		}

		setLocalStorageRetrieved(true);
	}, []);

	useEffect(() => {
		if (!localStorageRetrieved) return;

		localStorage.setItem(currencyCodeLocalStorageKey, currencyCode);
	}, [currencyCode, localStorageRetrieved]);

	return (
		<CurrencyContext.Provider value={{ currencyCode, setCurrencyCode }}>
			{children}
		</CurrencyContext.Provider>
	);
};
